import EditGateway from '@/layouts/Stack/components/EditGateway.vue';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import BaseLoader from '@/components/BaseLoader/BaseLoader.vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorOpenStackModal, modals],
  computed: {
    id() {
      return this.tariff.id;
    },
  },
  methods: {
    editGateway: function (item) {
      const that = this;
      let self;
      return new Promise(() => {
        const selfName = 'EditGateway';
        const id = item.id;
        this.$modals.open({
          name: selfName,
          size: 'small',
          component: EditGateway,
          closeOnBackdrop: false,
          props: {
            instance: item.action.subnet.name,
          },
          text: this.$t('sure.text'),
          on: {
            instance: data => {
              item = data;
            },
          },
          // onOpen: inst => (this.modal = inst),
          onOpen(inst) {
            self = inst;
          },
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm'), loading: false },
              on: {
                click: () => {
                  // self.text = null;
                  self.component = BaseLoader;
                  self.closable = false;
                  self.footer = false;
                  this.newGateway(item, id)
                    .then(data => {
                      this.$modals.close({ name: selfName });
                      this.showResModal('Шлюз успешно изменен');

                      // }
                    })
                    .catch(e => {
                      that.$modals.close();
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    newGateway(ip, id) {
      // console.log(item.action);
      return this.$store.dispatch('moduleStack/updateSubnet', {
        subnet: {
          gateway_ip: ip ? ip : null,
        },
        id: id,
        item: 'gateway_ip',
      });
    },
    // makeModal(props = {}) {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //     ...props,
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal(props);
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     cancel: {
    //       props: { title: this.$t('close') },
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
