<template>
  <div class="resize">
    <div class="l-col">
      <base-input
        v-model="dnsNames"
        type="textarea"
        :hint="hintDns"
        :label="DescriptionName"
        :placeholder="$t('dns.placeholder')"
        @input="onChange($event)"
      >
        <p v-if="error" slot="storageError">{{ $t(`${error}`) }}</p>
      </base-input>
    </div>
    <plain-button
      v-if="defaultDnsNames !== dnsNames"
      color="primary"
      class="tariffs-table__prolong l-col"
      @click="setDnsDefault"
    >
      {{ $t('dns.restore') }}
    </plain-button>
  </div>
</template>

<script>
import setFocus from '@/mixins/setFocus';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
export default {
  name: 'SubnetDns',
  components: { BaseInput },
  mixins: [setFocus],
  props: {
    dns: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      error: '',
      newDescription: '',
      dnsNames: '',
      defaultDnsNames: '',
    };
  },
  computed: {
    hintDns() {
      return `Корректная работа сети гарантируется только при использовании наших DNS-серверов:<br />
${this.defaultDnsNames.split('\n')[0]}<br />
${this.defaultDnsNames.split('\n')[1]}<br />
`;
    },
    DescriptionName() {
      return this.text;
    },
  },
  watch: {
    dnsNames: {
      handler: function (event) {
        const arrayOfErrors = [];
        const dnsNames = event.split('\n');
        dnsNames.forEach(dns => {
          arrayOfErrors.push(this.isIPv4(dns.trim()));
        });
        const uniqDnsNames = Array.from(new Set(dnsNames));
        this.error =
          arrayOfErrors.length > 4
            ? 'error.length'
            : arrayOfErrors.includes(false)
            ? 'error.false'
            : uniqDnsNames.length !== dnsNames.length
            ? 'error.dublicate'
            : '';
        if (this.error) this.$emit('notready');
      },
    },
  },
  mounted() {
    this.getDnsDefault();
  },
  methods: {
    getDnsDefault() {
      this.dnsNames = this.dns.join('\n');
      this.defaultDnsNames = this.$store.state.moduleStack.dnsDefault.join('\n');
    },
    isIPv4(addr) {
      return /^(([01]?\d{1,2}|2[0-4]\d|25[0-5])(\.|$)){4}$/.test(addr);
    },
    setDnsDefault() {
      this.dnsNames = this.defaultDnsNames;
      this.$emit('change', this.defaultDnsNames.split('\n'));
    },
    onChange(event) {
      const checkDns = event.split('\n').map(x => this.isIPv4(x.trim()));
      if (checkDns.includes(false)) {
        this.$emit('notready');
        // return;
      } else
        this.$emit(
          'change',
          this.dnsNames
            ? this.dnsNames.split('\n').filter(x => x.length)
            : this.defaultDnsNames.split('\n')
        );
    },
  },
};
</script>
<i18n>
{
"ru": {
  "error": {
    "created":"Сеть с таким именем уже существует.",
    "length":"От 1 до 4, ввести можно только IPv4 по одному на строку.",
    "false": "Неверный формат IPv4.",
    "dublicate": "Дублирование имен DNS.",
    "new":"Длина имени сети не должна превышать 255 символов."
  },
  "dns": {
    "restore": "Вернуть DNS по умолчанию",
    "placeholder": "DNS адреса"
  }
}}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.resize {
  text-overflow: unset;
  overflow: visible;
  &-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: .75rem .75rem;
}
}
</style>
