import { kFormatter } from '@/utils/parsers/sizeParser';

function divideForItem(i, a) {
  const value = i / Math.pow(1024, a) || 0;
  const isFloat = value.toString().split('.').length > 1;
  // console.log('***********************************', i, 'isFloat', isFloat);
  return isFloat ? value.toFixed(2) : value;
  // console.log(n);
  // console.log(Number(n) === n && n % 1 !== 0);
  // return Number(n) === n && n % 1 !== 0;
}

export function objectSizeParser(item) {
  // console.log(typeof item);
  const size = item / Math.pow(1024, 1);
  // getFormattedBytesValue(value, unit) {
  // },
  // console.log(typeof size, typeof (item / Math.pow(1024, 1)));

  // isFloat(item / Math.pow(1024, 1));

  // console.log('size', size, item, Math.pow(1024, 1), item / Math.pow(1024, 1));
  switch (true) {
    case size < 1:
      return `${item} Б`;
    case size < 1024:
      // console.log(divideForItem(item, 1));
      // console.log(divideForItem(item, 1).split('.'));
      // console.log(
      //   `${
      //     divideForItem(item, 1).includes('.')
      //       ? divideForItem(item, 1).toFixed(2)
      //       : divideForItem(item, 1)
      //   } Kб`
      // );

      // return `${(item / Math.pow(1024, 1)).toFixed(2)} Kб`;
      // return `${(item / Math.pow(1024, 1)).toFixed(2)} Kб`;
      // return `${divideForItem(item, 1).toFixed(2)} Kб`;
      return `${divideForItem(item, 1)} Kб`;
    case size < 1024 ** 2:
      // console.log(divideForItem(item, 2));
      // console.log(divideForItem(item, 2).split('.'));
      // console.log(size, 1024 ** 2, item);
      // console.log(`${(item / Math.pow(1024, 2)).toFixed(2)} МБ`);
      // return `${(item / Math.pow(1024, 2)).toFixed(2)} МБ`;
      // return `${divideForItem(item, 2).toFixed(2)} МБ`;
      return `${divideForItem(item, 2)} МБ`;
    case size < 1024 ** 3:
      // console.log(divideForItem(item, 3));
      // console.log(divideForItem(item, 3).split('.'));
      // console.log(size, 1024 ** 3, item);
      // console.log(`${(item / Math.pow(1024, 3)).toFixed(2)} ГБ`);
      // return `${(item / Math.pow(1024, 3)).toFixed(2)} ГБ`;
      // return `${divideForItem(item, 3).toFixed(2)} ГБ`;
      return `${divideForItem(item, 3)} ГБ`;
    case size < 1024 ** 4:
      // return `${(item / Math.pow(1024, 4)).toFixed(2)} TБ`;
      // return `${divideForItem(item, 4).toFixed(2)} TБ`;
      return `${divideForItem(item, 4)} TБ`;
    default:
      return NaN;
  }
}
