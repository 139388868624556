<template>
  <div class="storage">
    <transition name="bubble">
      <page-block class="storage-view__block">
        <base-empty
          v-if="tariff.policyList === 'nopolicy'"
          :title="$t('empty.title')"
          :text="$t('empty.text')"
          class="storage__empty"
          ><template #link>
            <base-button :loading="isEmpty" @click="addPolicy">
              {{ $t('empty.add') }}
            </base-button>
          </template>
        </base-empty>

        <main-card v-else>
          <base-loader v-if="loader" />
          <div v-else>
            <div class="addons__label medium-title">
              {{ $t('params') }}
            </div>
          </div>
        </main-card>
      </page-block>
    </transition>
  </div>
</template>

<script>
import MainCard from '@/components/MainCard/MainCard';
import RadioButton from 'primevue/radiobutton';
import { OPStorage } from '@/models/OP/OPStorage';
import Button from 'primevue/button';
import modals from '@/mixins/modals';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import { dev } from '@/layouts/LoginPage/mixins/currentProvider';
import { objectSizeParser } from '@/utils/parsers/objectSizeParser';
import BaseEmpty from '@/components/BaseEmpty/BaseEmpty.vue';
export default {
  name: 'BucketPolicy',
  components: {
    BaseEmpty,
    MainCard,
  },
  mixins: [modals, showErrorOpenStackModal],
  props: {
    tariff: {
      type: OPStorage,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      mode: 'base',
      isEmpty: false,
      loader: false,
      selectedCategory: null,
      currentSize: '',
      currentCount: '',
      categories: [
        { name: 'private', key: 'private' },
        { name: 'public', key: 'public-read' },
      ],
      formData: {},
    };
  },
  computed: {
    id() {
      return this.$store.state.moduleStack.current;
    },

    s3key() {
      return this.$store.state.moduleStack.s3Keys[0];
    },
  },
  watch: {
    tariff: {
      handler: function (event) {
        console.log(event);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.$store.dispatch('moduleStack/getS3StoragesKeys', this.id);
    this.updateStorageInfo('/');
    // this.getStackPrice();
    // this.getS3BucketList();
    // this.getS3Limit();
  },
  methods: {
    addPolicy(event) {
      console.log(event);
    },
    async updateStorageInfo(prefix) {
      this.loader = true;
      const params = {
        storage: this.tariff.name,
        objectForAcl: true,
      };
      if (prefix) params.prefix = prefix;
      await this.$store
        .dispatch('moduleStack/fetchCurrentStorages', params)
        .finally(() => (this.loader = false));
    },
  },
};
</script>

<i18n>
  {
    "ru": {
      "all": "Применить к содержимому контейнера",
      "name": "Имя",
      "limits": "Лимиты",
      "empty": {
        "title": "Создайте политику ",
        "add": "Создать политику доступа к контейнеру",
        "text": "и управляйте доступом пользователей к контейнеру, папке или объекту."
      },
      "cost": "Стоимость",
      "disk": "Диск",
      "s3": "URL-адрес конечной точки объектного хранилища (S3 endpoint URL):",
      "useSSL": "Использование SSL для защищенного соединения:",
      "region": "Регион облачного хранилища:",
      "params": "Параметры объектного хранилища",
      "descr": "Применить ко всем вложенным объектам контейнера.",
      "cache": "Кэширование",
      "container": "Тип контейнера",
      "type": "Настройки конфиденциальности",
      "x-container-meta-quota-bytes": "Суммарный размер, ГБ",
      "x-container-meta-quota-count": "Количество, шт",
      "text": "Для сброса лимита - уберите символы из соответствующих полей. Настройки лимитов применяются только при загрузке новых файлов и не влияют на уже хранящиеся файлы.",
      "sum": "Суммарный размер, ГБ",
      "currentSize": "Текущий объем контейнера",
      "currentCount": "Текущее количество объектов",
      "private": "Приватный",
      "public": "Публичный",
      "count": "Количество, шт",
      "limit": "Лимиты объектов контейнера",
      "port": "Входящий порт",
      "vppr": "Служебные порты",
      "cpu": "vCPU",
      "ram": "Память",
      "networkDisk": "Сетевой диск"
    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.storage {
  width: 100%
  &__empty {
    padding-top: 6.75rem;
  }
  &-view{
    &__block{
      margin-top: 1rem;
    }
  }
}
.tariff {
  &-plan,
  &-autoprolong {

    &__btns {
      margin: -0.5em -0.75rem;
    }

    &__btn {
      min-width: 130px;
      margin: 0.5em 0.75rem;
    }
  }
}
.field-radiobutton {
  margin-right: 1rem
}
.cost {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  &__label{
    width: 6rem
  }

  &__group{
    +breakpoint(ms-and-up) {
      display: flex;
      //gap: 1rem;
      width: 100%;
      justify-content: space-between;
    }
  }


}
.limits {
  display: flex;
  //gap: 5rem;
  justify-content: flex-start;
  //justify-content: space-between;
  &__label{
    width: 6rem
  }
  //&__value {
  //  margin-left: 0;
  //}

}
.addons {
  &__input{
    height: 36px;
    width: 100%
  }
  &__value{
    min-width: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  &-radio {
      margin-right: 0.5rem
    }
  }

  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
      height: 45px;
    }

    &-btn {
      width: 130px;
      margin-top: 1rem

      & + & {
        margin-left: 2rem
      }
    }

    & + & {

      +breakpoint(sm-and-up) {
        margin-top: -0.8rem;
        transition: border-color $anim-base-duration;
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 32rem;
      margin-right: 0.5rem;
      //margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}
</style>
