import AllocationPools from '@/layouts/Stack/components/AllocationPools.vue';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorOpenStackModal, storeMixin, modals],
  data() {
    return {
      data: {},
      dns: '',
      isProcessing: false,
    };
  },
  methods: {
    editAllocationPools: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'AllocationPools';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: AllocationPools,
          closeOnBackdrop: false,
          props: {
            allocationPools: instance.action.subnet.pools,
            // text: this.$t('actions.dnsname'),
          },
          on: {
            change: data => {
              this.pools = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  const params = {
                    params: {
                      subnet: { allocation_pools: this.pools },
                    },
                    item: 'allocation_pools',
                    id: this.id,
                  };
                  this.updatePrivateSubnet(params)
                    .then(data => {
                      if (data) {
                        this.$emit('change-pool');
                        that.$modals.close();
                        this.showResModal('Пул IP-адресов успешно изменён.');
                      }
                    })
                    .catch(e => {
                      that.$modals.close();
                      let test = this.parsingError(e.response.data['NeutronError']);
                      this.showError(test, 'allocationPools');
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    parsingError(e) {
      let text;
      switch (e.type) {
        case 'HTTPBadRequest':
          text = e.message.match(/\'(.*?)\'/g);
          return `${text} не является корректным IP-адресом.`;
        case 'InvalidAllocationPool':
          text = e.message.match(/\{(.*?)\}/g);
          let splittedText = text[0].match(/\'(.*?)\'/g);
          return `Пул адресов (${splittedText[1].slice(1, -1)} - ${splittedText[3].slice(
            1,
            -1
          )}) некорректен.`;
        case 'OutOfBoundsAllocationPool':
          text = e.message;
          return `Пул ${text.split(' ')[3]} выходит за пределы сети ${text.split(' ').at(-1)}`;
        default:
          return e.message;
      }
    },
    updatePrivateSubnet(params) {
      return this.$store.dispatch('moduleStack/updateSubnetDns', params);
    },
  },
};
