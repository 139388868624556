import SubnetDns from '@/layouts/Stack/components/SubnetDns.vue';
import showErrorOpenStackModal from '@/mixins/showErrorOpenStackModal';
import storeMixin from '@/layouts/Stack/mixins/index';
import Vue from 'vue';
import modals from '@/mixins/modals';

export default {
  mixins: [showErrorOpenStackModal, storeMixin, modals],
  data() {
    return {
      data: {},
      dns: '',
      isProcessing: false,
    };
  },
  methods: {
    changeSubnetDns: function (instance) {
      const that = this;
      return new Promise(() => {
        const selfName = 'SubnetDns';
        this.$modals.open({
          name: selfName,
          size: 'medium',
          component: SubnetDns,
          closeOnBackdrop: false,
          props: {
            dns: instance.action.subnet.dns,
            text: this.$t('actions.dnsname'),
          },
          on: {
            change: data => {
              // console.log(data);
              this.dns = data;
              Vue.set(this.modal.footer.confirm.props, 'disabled', false);
            },
            notready: () => {
              Vue.set(this.modal.footer.confirm.props, 'disabled', true);
            },
          },
          onOpen: inst => (this.modal = inst),
          onClose: () => (this.modal = null),
          onDismiss: () => (this.modal = null),
          footer: {
            confirm: {
              props: { title: this.$t('sure.confirm') },
              on: {
                click: () => {
                  Vue.set(this.modal, 'footer', {
                    confirm: {
                      props: { loading: true },
                    },
                  });
                  const params = {
                    params: {
                      subnet: { dns_nameservers: this.dns },
                    },
                    item: 'dns_nameservers',
                    id: this.id,
                  };
                  this.updatePrivateSubnet(params)
                    .then(data => {
                      if (data) {
                        that.$modals.close();
                        this.showResModal('DNS-серверы приватной подсети изменены.');
                      }
                    })
                    .catch(e => {
                      that.$modals.close();
                      this.showError(e);
                    });
                },
              },
            },
            cancel: {
              on: {
                click: () => {
                  that.$modals.close();
                },
              },
            },
          },
        });
      });
    },
    updatePrivateSubnet(params) {
      console.log(params);
      return this.$store.dispatch('moduleStack/updateSubnetDns', params);
    },
    // makeModal() {
    //   this.$modals.open({
    //     name: 'AddModal',
    //     html: true,
    //     size: 'small',
    //     onOpen: inst => (this.modal = inst),
    //     onClose: () => (this.modal = null),
    //     onDismiss: () => (this.modal = null),
    //   });
    // },
    // showResModal(res, props = {}) {
    //   if (!this.modal) this.makeModal();
    //   Vue.set(this.modal, 'component', null);
    //   Vue.set(this.modal, 'closable', true);
    //   Vue.set(this.modal, 'text', res);
    //   Vue.set(this.modal, 'footer', {
    //     confirm: {
    //       on: {
    //         click: () => {
    //           this.$modals.close();
    //         },
    //       },
    //     },
    //   });
    // },
  },
};
